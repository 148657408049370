<template>
	<div class="h-100 w-100 d-flex flex-column">
		<BaseLoading v-if="isLoading" nameLoading="loading-erp" :labelLoading="labelLoading" />

		<div class="h-100 w-100 d-flex flex-column">
			<div
				class="container-fluid shadow-sm bg-white shadow-hover card-panel d-flex flex-column"
				style="height: -webkit-fill-available"
			>
				<Header />

				<hr />

				<Table />

				<Footer />
			</div>

			<ModalErros v-if="state.isShowModalErros" />

			<ModalFormulario v-if="state.isShowModalFormulario" />
		</div>
	</div>
</template>

<script>
	import { mapState, mapGetters } from 'vuex';
	import { mapWaitingActions, mapWaitingGetters } from 'vue-wait';

	import Header from '@/components/produtos/importacao/ProdutosImportacaoHeader.vue';
	import Table from '@/components/produtos/importacao/ProdutosImportacaoTable.vue';
	import Footer from '@/components/produtos/importacao/ProdutosImportacaoFooter.vue';
	import ModalErros from '@/components/produtos/importacao/ProdutosImportacaoModalErros.vue';
	import ModalFormulario from '@/components/produtos/importacao/ProdutosImportacaoModalFormulario.vue';

	export default {
		name: 'ProdutosImportacao',
		components: {
			Header,
			Table,
			Footer,
			ModalErros,
			ModalFormulario,
		},
		computed: {
			...mapState({
				state: (state) => state.importacao,
				autenticacao: (state) => state.autenticacao,
			}),
			...mapGetters('importacao', {
				isLoading: 'isProdutosImportacaoLoading',
				labelLoading: 'labelLoading',
			}),
		},
		methods: {
			...mapWaitingActions('importacao', {
				obterDadosIniciaisPainelImportacoes: 'obterDadosIniciaisPainelImportacoes importacao',
				conectarHubProdutosImportacao: 'conectarHubProdutosImportacao importacao',
				atualizarListagemPainelImportacoes: 'atualizarListagemPainelImportacoes importacao',
			}),
		},
		mounted() {
			this.conectarHubProdutosImportacao();
			this.obterDadosIniciaisPainelImportacoes();
		},
		destroyed() {
			this.$store.commit('updateHubConnectionToStop');
		},
	};
</script>

<style scoped></style>
