<template>
  <BaseModal
    @close="closeModalErros()"
    :classesAdicionaisModal="'rounded w-90 w-sm-100 h-100 position-relative modal-size-importacao'"
    :tituloModal="'VISUALIZAR INCONSISTÊNCIAS'"
    :classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx py-1 px-2'"
    :classesAdicionaisFooter="'p-0'"
    :classesAdicionaisBody="'section-modal-importacao-produtos-style'"
    id="modalVisualizarErros"
    :telaInteira="true"
  >
    <template v-if="isLoading" v-slot:loading>
      <BaseLoading nameLoading="loading-erp" :isLoadingFullScreen="false" :labelLoading="labelLoading" />
    </template>
    <template v-else v-slot:body>
      <div class="container-fluid body-div-container-style">
        <div class="row mb-4">
          <div class="col-12 d-flex">
            <label class="font-weight-bold" style="font-size: 16px">Arquivo: </label
            ><label class="text-uppercase flex-wrap" style="font-size: 13px; margin-top: 4px">{{ nomeArquivo }}</label>
          </div>
        </div>
        <div class="row body-div-row-overflow">
          <div class="col-12 d-flex justify-content-end align-items-end">
            <label class="registros-por-pagina-label">Registros por página</label>
            <select v-model="registrosPorPagina" class="form-control form-control-sm registros-por-pagina-select">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col-12 body-div-table-overflow">
            <table class="table table-sm table-striped table-hover lmx-tabela table-bordered m-0">
              <thead class="tableFixHead">
                <tr>
                  <th style="width: 90px" class="cursor-pointer text-center" @click="ordenarPor('Linha')">
                    Linha
                    <span v-show="ordenacao.Tipo == 1"><i class="ml-1 fas fa-sort-up"></i> </span>
                    <span v-show="ordenacao.Tipo == 0"> <i class="ml-1 fas fa-sort-down"></i></span>
                  </th>
                  <th>Descrição</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(divergencia, idx) in listaOrdenada.slice(indexInicial, indexFinal)" :key="'lista-ordenada:' + idx">
                  <td class="align-middle text-center">
                    <span>{{ divergencia.Linha }}</span>
                  </td>
                  <td class="d-flex flex-column">
                    <span v-for="(erro, index) in divergencia.Erros" :key="'divergencia:' + index">{{ erro }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span> {{ registrosComeco }} a {{ registrosAtuais }} de {{ registrosTotais }} registros</span>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-center" v-if="totalPaginas > 1">
          <div v-for="(pagina, index) in totalPaginas" :key="index">
            <button
              v-if="verificarSeExibeBotaoPagina(pagina)"
              class="btn btn-default"
              :class="{
                active: pagina == paginacao.paginaAtual,
                'm-1': true,
              }"
              @click="paginacao.paginaAtual = pagina"
            >
              {{ pagina }}
            </button>
            <span v-else-if="verificarSeExibeBotaoPagina(pagina - 1)" class="m-1">...</span>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="modal-footer p-0 bg-white d-flex justify-content-center align-items-center w-100">
        <button class="btn btn-primary shadow-sm flex-grow-1" @click="closeModalErros">
          <span>FECHAR</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  import swal from '@/common/alerts';

  import { mapWaitingActions, mapWaitingGetters } from 'vue-wait';

  export default {
    name: 'ImportacaoModalErros',
    data() {
      return {
        nomeArquivo: '',
        divergencias: [],
        registrosPorPagina: 10,
        ordenacao: {
          Campo: 'Linha',
          Tipo: 1,
          Enum: { Asc: 1, Desc: 0 },
        },
        paginacao: {
          paginaAtual: 1,
        },
      };
    },
    computed: {
      ...mapState({
        state: (state) => state.importacao,
      }),
      ...mapGetters('importacao', {
        isLoading: 'isImportacaoModalErrosLoading',
        labelLoading: 'labelLoading',
      }),
      listaOrdenada() {
        const divergenciasAux = [...this.divergencias];
        return divergenciasAux.sort((a, b) => {
          if (this.ordenacao.Tipo == this.ordenacao.Enum.Asc) return a[this.ordenacao.Campo] - b[this.ordenacao.Campo];
          if (this.ordenacao.Tipo == this.ordenacao.Enum.Desc) return b[this.ordenacao.Campo] - a[this.ordenacao.Campo];
        });
      },
      totalPaginas() {
        return Math.ceil(this.listaOrdenada.length / this.registrosPorPagina);
      },
      indexInicial() {
        return this.registrosPorPagina * (this.paginacao.paginaAtual - 1);
      },
      indexFinal() {
        var indexFinal = Number(this.registrosPorPagina) * (this.paginacao.paginaAtual - 1) + Number(this.registrosPorPagina);

        return indexFinal;
      },
      registrosTotais() {
        return this.listaOrdenada.length;
      },
      registrosAtuais() {
        if (this.registrosTotais === 0) return 0;

        if (this.registrosTotais < this.registrosPorPagina) return this.registrosTotais;

        if (this.indexFinal > this.registrosTotais) return this.registrosTotais;

        return this.indexFinal;
      },
      registrosComeco() {
        if (this.registrosTotais === 0) return 0;

        if (this.registrosPorPagina > this.registrosTotais) return 1;

        return this.indexInicial + 1;
      },
    },
    watch: {
      totalPaginas(newVal, oldVal) {
        if (this.paginacao.paginaAtual > newVal) this.paginacao.paginaAtual = 1;
      },
    },
    methods: {
      ...mapWaitingActions('importacao', {
        buscarInconsistencias: 'buscarInconsistencias importacao',
      }),
      obterDivergenciasImportacao() {
        this.buscarInconsistencias()
          .then((jsonResponse) => {
            this.nomeArquivo = jsonResponse.NomeArquivo;
            this.divergencias = jsonResponse.Divergencias;

            this.registrosPorPagina = 10;
            this.paginacao.paginaAtual = 1;
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          });
      },
      obterClasseOrdenacao(nomeColuna) {
        var sort = 'fas fa-sort';
        var sortAsc = 'fas fa-sort-up';
        var sortDesc = 'fas fa-sort-down';

        if (nomeColuna != this.ordenacao.Campo) return sort;

        if (nomeColuna == this.ordenacao.Campo && this.ordenacao.Tipo == this.ordenacao.Enum.Asc) return sortAsc;

        return sortDesc;
      },
      ordenarPor(nomeColuna) {
        if (nomeColuna != this.ordenacao.Campo) {
          this.ordenacao.Campo = nomeColuna;
          this.ordenacao.Tipo = this.ordenacao.Enum.Asc;
        }

        if (nomeColuna == this.ordenacao.Campo && this.ordenacao.Tipo == this.ordenacao.Enum.Asc)
          return (this.ordenacao.Tipo = this.ordenacao.Enum.Desc);

        return (this.ordenacao.Tipo = this.ordenacao.Enum.Asc);
      },
      verificarSeExibeBotaoPagina(pagina) {
        return (
          this.paginacao.paginaAtual == pagina ||
          this.paginacao.paginaAtual + 1 == pagina ||
          this.paginacao.paginaAtual - 1 == pagina ||
          this.totalPaginas == pagina ||
          this.totalPaginas - 1 == pagina ||
          1 == pagina ||
          2 == pagina
        );
      },
      closeModalErros() {
        this.$store.commit('importacao/updateIsShowModalErrosToClosed');
      },
    },
    mounted() {
      this.obterDivergenciasImportacao();
    },
  };
</script>

<style scoped>
  .lmx-tabela thead,
  tfoot {
    background-color: #5b2e90;
    color: white;
  }

  .lmx-tabela tbody {
    font-size: 90%;
  }

  .lmx-tabela-linha-totais {
    color: #ffffff;
    font-weight: bold;
    background-color: #5b2e90;
  }

  .registros-por-pagina-label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 4px 0 0;
  }

  .registros-por-pagina-select {
    height: 100%;
    max-width: 67px;
  }

  .overflow-modal-erros {
    overflow: hidden !important;
  }

  .table-height {
    max-height: 100vh;
  }

  .body-div-container-style {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: hidden !important;
    width: 100% !important;
  }

  .body-div-row-overflow {
    overflow: hidden !important;
    height: 100% !important;
  }

  .body-div-table-overflow {
    overflow: overlay !important;
    height: calc(100% - 31px);
  }

  .tableFixHead {
    position: sticky;
    top: -0.1px;
    z-index: 1;
  }
</style>
