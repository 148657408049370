<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-end">
        <button class="btn btn-secondary" @click="abrirModalVisualizarErros" :disabled="!habilitarButtonVisualizarErros">
          Visualizar erros
        </button>

        <button
          class="btn btn-secondary mx-2"
          @click="downloadArquivoComDivergenciasImportacao"
          :disabled="!habilitarButtonDownloadInconsistencias"
        >
          Gerar arquivo com inconsistências
        </button>

        <button class="btn btn-danger" @click="cancelarImportacaoSelecionada" :disabled="!habilitarButtonCancelarImportacao">
          Cancelar importação
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { mapWaitingActions, mapWaitingGetters } from 'vue-wait';

  import swal from '@/common/alerts';

  export default {
    name: 'ImportacaoFooter',
    computed: {
      ...mapState({
        state: (state) => state.importacao,
      }),
      habilitarButtonVisualizarErros() {
        return (
          this.state.importacaoSelecionada &&
          this.state.importacaoSelecionada.QtdeRegistrosDivergentes > 0 &&
          (this.state.importacaoSelecionada.IdSituacaoImportacao === this.state.situacoesImportacao.concluidoComInconsistencias ||
            this.state.importacaoSelecionada.IdSituacaoImportacao === this.state.situacoesImportacao.cancelado)
        );
      },
      habilitarButtonDownloadInconsistencias() {
        return (
          this.state.importacaoSelecionada &&
          this.state.importacaoSelecionada.QtdeRegistrosDivergentes > 0 &&
          (this.state.importacaoSelecionada.IdSituacaoImportacao === this.state.situacoesImportacao.concluidoComInconsistencias ||
            this.state.importacaoSelecionada.IdSituacaoImportacao === this.state.situacoesImportacao.cancelado)
        );
      },
      habilitarButtonCancelarImportacao() {
        return (
          this.state.importacaoSelecionada &&
          this.state.importacaoSelecionada.IdSituacaoImportacao === this.state.situacoesImportacao.processando
        );
      },
    },
    methods: {
      ...mapWaitingActions('importacao', {
        cancelarImportacao: 'cancelarImportacao importacao',
        downloadArquivoComDivergencias: 'downloadArquivoComDivergencias importacao',
      }),
      downloadArquivoComDivergenciasImportacao() {
        this.downloadArquivoComDivergencias().catch((erro) => {
          let mensagemErro = '';

          if (erro.response) {
            if (erro.response.status == 400) mensagemErro = erro.data;
            else mensagemErro = erro.message;
          } else mensagemErro = erro;

          swal.exibirMensagemErro(mensagemErro);
          return Promise.reject(mensagemErro);
        });
      },
      cancelarImportacaoSelecionada() {
        this.cancelarImportacao()
          .then(() => {
            this.$store.commit('importacao/updateSituacaoImportacaoSelecionada', this.state.situacoesImportacao.cancelado);
            swal.exibirMensagemSucesso('A importação foi cancelada com sucesso.');
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          });
      },
      abrirModalVisualizarErros() {
        this.$store.commit('importacao/updateIsShowModalErrosToOpen');
      },
    },
    mounted() {},
  };
</script>
<style></style>
