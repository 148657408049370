<template>
  <div class="h-100 w-100 overflow-hidden">
    <BaseLoading
      v-if="isLoading"
      nameLoading="loading-erp"
      :isLoadingFullScreen="false"
      class="d-flex flex-column overflow-hidden h-100 w-100"
      :labelLoading="labelLoading"
    />

    <div v-else class="d-flex flex-column overflow-hidden h-100 w-100">
      <template v-if="state.listaDeImportacoesFiltradas.length !== 0">
        <div class="row">
          <div class="col-12">
            <h5 class="h5" v-html="isListaDeImportacoesFiltrada ? 'Resultados pesquisa' : 'Últimas Importações'"></h5>
          </div>
        </div>
        <div class="row overflow-hidden flex-grow-1 mb-1">
          <div class="col-12 w-100 h-100">
            <div class="h-100 w-100 d-flex flex-column">
              <div class="table-responsive-md overflow-auto flex-grow-1 p-0">
                <table class="table table-sm table-striped table-hover lmx-tabela-relatorio">
                  <thead class="importacao-table-header-style">
                    <tr>
                      <th>Data</th>
                      <th>Arquivo</th>
                      <th>Status</th>
                      <th>Registros</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(importacao, index) in state.listaDeImportacoesFiltradas"
                      :key="index"
                      @click="selecionarImportacaoGrid(importacao)"
                      :class="buscarClasseLinhaGrid(importacao.Id)"
                    >
                      <td class="align-middle">
                        {{ importacao.DataCriacao | formatarDataHora }}
                      </td>
                      <td class="align-middle">{{ importacao.NomeArquivo }}</td>
                      <td class="align-middle">
                        <div class="d-flex">
                          {{ obterDescricaoSituacao(importacao.IdSituacaoImportacao) }}
                          <div class="ml-1" v-if="isImportacaoProcessando(importacao)">
                            <i style="color: rgb(44, 0, 75); font-size: 15px" class="fas fa-circle-notch fa-spin fa-3x fa-fw"></i>
                          </div>
                        </div>
                      </td>
                      <td class="align-middle">
                        <span
                          class="badge badge-pill badge-success"
                          data-toggle="tooltip"
                          data-html="true"
                          title="Registros Criados"
                          >{{ importacao.QtdeRegistrosCriados }}</span
                        >
                        <span
                          class="badge badge-pill badge-secondary ml-1"
                          data-toggle="tooltip"
                          data-html="true"
                          title="Registros Alterados"
                          >{{ importacao.QtdeRegistrosAlterados }}</span
                        >
                        <span
                          class="badge badge-pill badge-danger ml-1"
                          data-toggle="tooltip"
                          data-html="true"
                          title="Registros Divergentes"
                          >{{ importacao.QtdeRegistrosDivergentes }}</span
                        >
                        <span
                          class="badge badge-pill badge-default ml-1"
                          data-toggle="tooltip"
                          data-html="true"
                          title="Total de Registros"
                          >{{ importacao.TotaldeRegistros }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <small class="text-muted pl-2">
              Qtde. de Registros:
              <b>{{ state.listaDeImportacoesFiltradas.length }}</b>
            </small>
          </div>
        </div>
      </template>

      <div
        class="d-flex flex-grow-1 justify-content-center align-items-center w-100 h-100"
        v-if="state.listaDeImportacoesFiltradas.length === 0 && !isLoading"
      >
        <div class="text-center">
          <i class="fa fa-exclamation text-primary fa-4x"></i>
          <h3 style="color: #2c004b">Nenhuma importação encontrada!</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { mapWaitingActions, mapWaitingGetters } from 'vue-wait';

  export default {
    name: 'ImportacaoTable',
    filters: {
      formatarDataHora(strData) {
        if (!strData) return '';
        var data = new Date(strData);
        return data.toLocaleString('pt-BR');
      },
    },
    computed: {
      ...mapState({
        state: (state) => state.importacao,
      }),
      ...mapGetters('importacao', {
        isListaDeImportacoesFiltrada: 'isListaDeImportacoesFiltrada',
        isLoading: 'isImportacaoTableLoading',
        labelLoading: 'labelLoading',
      }),
    },
    methods: {
      selecionarImportacaoGrid(importacao) {
        if (this.state.importacaoSelecionada && this.state.importacaoSelecionada.Id == importacao.Id)
          this.$store.commit('importacao/updateImportacaoSelecionada', null);
        else this.$store.commit('importacao/updateImportacaoSelecionada', importacao);
      },
      buscarClasseLinhaGrid(idImportacao) {
        if (this.state.importacaoSelecionada != null && this.state.importacaoSelecionada.Id == idImportacao) {
          return 'cursor-pointer linha-grid-selecionada';
        }
        return 'cursor-pointer';
      },
      obterDescricaoSituacao(idSituacao) {
        switch (idSituacao) {
          case 1:
            return 'Processando';
          case 2:
            return 'Concluído';
          case 3:
            return 'Concluído com inconsistências';
          case 4:
            return 'Cancelado';
          default:
            return '';
        }
      },
      isImportacaoProcessando(importacao) {
        return importacao.IdSituacaoImportacao == this.state.situacoesImportacao.processando;
      },
    },
  };
</script>

<style scoped>
  .linha-grid-selecionada {
    background-color: #ffb200 !important;
  }

  .lmx-tela-div-nenhum-registro {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .lmx-tela-div-nenhum-registro i {
    font-size: 3rem;
  }

  .importacao-table-header-style {
    background-color: #5b2e90;
    color: white;
    position: sticky;
    top: -0.1px;
    z-index: 1;
  }
</style>
