<template>
	<header>
		<div class="row">
			<div class="col-12">
				<h1 class="h1 lmx-tela-titulo d-block float-left"> Importação de Produtos </h1>
				<span
					class="badge badge-secondary float-left lmx-bagde-guia-rapido-entrada-nfe d-none d-md-block"
					style="margin-top: 6px; margin-left: 6px"
				>
					<a
						href="https://share.linx.com.br/pages/viewpage.action?pageId=212512559"
						target="_blank"
						title="Precisa de ajuda? Acesso nosso Guia rápido!"
					>
						<i class="fas fa-book"></i>
						Guia Rápido
					</a>
				</span>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-sm-6">
				<div class="input-group" @keypress.enter="filtrarImportacoes">
					<input
						id="inputFiltrarImportacoes"
						type="text"
						class="form-control form-control-sm"
						placeholder="Filtre por alguma informação da listagem..."
						aria-label="Filtre por alguma informação da listagem..."
						v-model="queryFiltroListagem"
						autocomplete="off"
					/>
					<div class="input-group-append">
						<button
							id="filtrarImportacoes"
							class="btn btn-sm btn-primary"
							type="button"
							@click="filtrarImportacoes()"
						>
							<i class="fas fa-search"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-sm-6 d-flex justify-content-end">
				<button class="btn btn-success mr-1" @click="atualizarListagemPainelImportacoes">
					<i class="fas fa-sync-alt"></i>
				</button>
				<button class="btn btn-primary" @click="abrirModalFormulario" :disabled="!PermitirImportar">
					<i class="fas fa-file-upload"></i>
					NOVA IMPORTAÇÃO
				</button>
			</div>
		</div>
	</header>
</template>

<script>
	import { mapWaitingActions, mapWaitingGetters } from 'vue-wait';

	export default {
		name: 'ImportacaoHeader',
		computed: {
			queryFiltroListagem: {
				get() {
					return this.$store.state.importacao.queryFiltroListagem;
				},
				set(value) {
					this.$store.commit('importacao/updateQueryFiltroListagem', value);
				},
			},
		},
		data() {
			return { PermitirImportar: true };
		},
		async mounted() {
			var result = await this.obterDadosIniciaisProduto();
			this.PermitirImportar =
				!result.ParametrosGlobais.HabilitarPrecadastroProdutos || result.ParametrosAdm.PortalFranqueador;
		},
		methods: {
			...mapWaitingActions('importacao', {
				filtrarImportacoes: 'filtrarImportacoes importacao',
				atualizarListagemPainelImportacoes: 'atualizarListagemPainelImportacoes importacao',
				obterDadosIniciaisProduto: 'obterDadosIniciaisProduto importacao',
			}),
			abrirModalFormulario() {
				this.$store.commit('importacao/updateIsShowModalFormularioToOpen');
			},
		},
	};
</script>

<style></style>
